import * as React from "react";
import { graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import Celebrating30Years from "../components/celebrating30Years.js";
import Footer from "../components/footer.js";
import GdprPanel from "../components/gdpr.js";
import HomeEventPanel from "../components/homeEventPanel";
import HomeHeader from "../components/homeHeader.js";
import Navigation from "../components/navigation.js";
import PageQuote from "../components/quote.js";
import Seo from "../components/seo.js";

import "../styles/home.scss";
import NewsletterSignup from "../components/newsletterSignUp.js";

// markup
const IndexPage = ({ data }) => {
  return (
    <>
      <SSRProvider>
        <link
          href="https://use.fontawesome.com/releases/v5.0.2/css/all.css"
          rel="stylesheet"
        />
        <Navigation />
        <HomeHeader
          headline={data.mdx.frontmatter.headline}
          subHeadline={data.mdx.frontmatter.subheadline}
        />
        <div id="body" className="home">
          <div id="events" className="py-4 pt-lg-5 pb-lg-3">
            <Container fluid="lg" className="py-4 pt-lg-5 pb-lg-0 ">
              <HomeEventPanel></HomeEventPanel>
            </Container>
          </div>
          <Container fluid="lg">
            <div id="homeIntro" className="mt-lg-0 mb-lg-5 my-4 px-2 px-lg-0">
              <Row className="justify-content-between align-items-center py-xl-5 py-4">
                <Col
                  xl={6}
                  sm={12}
                  className="mb-4 pb-3 mb-xl-0 pb-xl-0 px-xl-0"
                >
                  <img
                    src="https://web-cdn.meridianuniversity.edu/site-content-images/home/homepage-intro-image.webp"
                    alt="golden state bridge in the sunlight"
                    className="img-fluid d-xl-block d-none"
                  />
                  <img
                    src="https://web-cdn.meridianuniversity.edu/site-content-images/home/homepage-intro-image-mobile.webp"
                    className="img-fluid d-xl-none d-block"
                    alt="golden state bridge in the sunlight mobile"
                  />
                </Col>
                <Col xl={5} sm={12}>
                  <p>
                    Founded over 30 years ago, Meridian University enrolls
                    students globally in its unique hybrid and 100% online
                    graduate degree, certificate, and public programs. The
                    University offers master's and doctoral programs in
                    Psychology, Education and Business with diverse
                    concentrations available within each of these programs.
                    Meridian’s integrated, innovative curriculum is designed to
                    be experiential and transformative.
                  </p>
                  <p>
                    Meridian graduates are engaged in varied professional
                    contexts that include coaching, psychotherapy,
                    organizational development, facilitation, social healing,
                    social entrepreneurship, and transformative leadership.
                  </p>
                  <p>
                    The University’s facilities include the Los Angeles Campus,
                    San Francisco Bay Area Center, and additional centers
                    outside of California.
                  </p>
                  <p>
                    Meridian’s graduate programs are available in two formats,
                    hybrid and 100% online. This model supports students who are
                    working professionals and balancing other life commitments
                    to move forward towards their personal and professional
                    future.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-around my-lg-4 py-xl-5 px-4 py-0 gx-5 intro-boxes">
                <Col
                  xs={12}
                  lg
                  className="p-lg-5 p-4 me-lg-3 d-flex mb-4 mb-lg-0 opaque-gray-bg"
                >
                  <Row>
                    <Col xs={12} className="d-flex align-items-center mb-3">
                      <div className="meridian-blue-15-bg rounded icon d-inline-flex">
                        <svg
                          width="17"
                          height="20"
                          viewBox="0 0 17 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6212 0H2.62115C1.52115 0 0.621155 0.9 0.621155 2V18C0.621155 19.1 1.52115 20 2.62115 20H14.6212C15.7212 20 16.6212 19.1 16.6212 18V2C16.6212 0.9 15.7212 0 14.6212 0ZM2.62115 2H7.62115V10L5.12115 8.5L2.62115 10V2Z"
                            fill="#0078AE"
                          />
                        </svg>
                      </div>
                      <span className="d-inline-flex ms-4">
                        <h3>Explore Our Programs</h3>
                      </span>
                    </Col>
                    <Col xs={12} className="pt-1">
                      <p className="mb-4">
                        Connect with Meridian faculty, staff, students, and
                        alumni.
                      </p>
                      <Link
                        to="/academics/degree-programs-overview"
                        className="btn btn-primary"
                      >
                        Explore{" "}
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="#FFFFFF"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg className="p-lg-5 p-4 ms-lg-3 opaque-gray-bg">
                  <Row>
                    <Col xs={12} className="d-flex align-items-center mb-3">
                      <div className="meridian-blue-15-bg rounded icon d-inline-flex">
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.6212 0C5.10115 0 0.621155 4.48 0.621155 10C0.621155 15.52 5.10115 20 10.6212 20C16.1412 20 20.6212 15.52 20.6212 10C20.6212 4.48 16.1412 0 10.6212 0ZM10.6212 15C10.0712 15 9.62115 14.55 9.62115 14V10C9.62115 9.45 10.0712 9 10.6212 9C11.1712 9 11.6212 9.45 11.6212 10V14C11.6212 14.55 11.1712 15 10.6212 15ZM11.6212 7H9.62115V5H11.6212V7Z"
                            fill="#0078AE"
                          />
                        </svg>
                      </div>
                      <span className="d-inline-flex ms-4">
                        <h3>Request Information</h3>
                      </span>
                    </Col>
                    <Col xs={12} className="pt-1">
                      <p className="mb-4">
                        Find out more about our degree programs and talk to an
                        admissions advisor.
                      </p>
                      <Link
                        to="/admissions/graduate-admissions/request-for-information"
                        className="btn btn-primary"
                      >
                        Learn More{" "}
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="#FFFFFF"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          <div id="quote">
            <PageQuote
              quote="Meridian University is a model for education: multidisciplinary, holistic, and practical. It offers a grounded vision of what is possible, both personally and socially, and how we can help create a better future."
              quoteAuthor="— RIANE EISLER"
              quoteAuthorAttribute="Author of the Chalice and the Blade, the Real Wealth of Nations, and Nurturing Our Humanity"
              quoteType="alumni-reverse"
              quoteHeadshot="https://web-cdn.meridianuniversity.edu/ipc-2021-headshots/eisler-ipc-headshot.webp"
            />
          </div>
          <div id="events" className="py-4 py-lg-5">
            <Container fluid="lg" className="py-4 py-lg-5">
              <Row>
                <Col className="px-0">
                  <div
                    id="oeVideoSection"
                    className="py-xl-5 py-4 mt-xl-5 mt-4"
                  >
                    <div className="video p-lg-4 p-xxl-5 p-3">
                      <Row className="justify-content-between align-items-center">
                        <Col xs={12} sm={6} lg={5} className="mb-4 mb-lg-0">
                          <Link to="/about/video-center/our-programs/educating-developmental-practitioners">
                            <img
                              src="https://web-cdn.meridianuniversity.edu/site-content-images/home/mu-homepage-video-thumb.webp"
                              class="img-fluid"
                              alt="Meridian University Logo Video Link Thumbnail"
                            />
                          </Link>
                        </Col>
                        <Col xs={12} lg={7} className="ps-lg-5">
                          <h5 className="mb-2">Aftab Omer, PhD</h5>
                          <h1 className="mb-3">
                            Educating Developmental Practitioners
                          </h1>
                          <div className="share-icons"></div>
                          <span className="video-description">
                            <p>
                              Recorded at the University's Los Angeles Campus,
                              Meridian President Aftab Omer, PhD, describes how
                              the University's programs are designed to use
                              transformative learning to educate developmental
                              practitioners, providing an experience unlike
                              traditional informational learning alone.
                            </p>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-around my-lg-4 py-xl-5 px-4 px-xl-2 py-0 gx-5 intro-boxes">
                <Col
                  xs={12}
                  lg
                  className="p-lg-5 p-4 me-lg-3 d-flex mb-4 mb-lg-0 white-bg"
                >
                  <Row>
                    <Col xs={12} className="d-flex align-items-center mb-3">
                      <div className="meridian-blue-15-bg rounded icon d-inline-flex">
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.45103 0.298799C3.67102 0.608799 0.0510254 4.7988 0.0510254 9.5888V16.2788C0.0510254 17.9388 1.39103 19.2788 3.05103 19.2788H4.05103C5.15103 19.2788 6.05103 18.3788 6.05103 17.2788V13.2788C6.05103 12.1788 5.15103 11.2788 4.05103 11.2788H2.05103V9.5688C2.05103 5.7288 5.01103 2.3888 8.84103 2.2788C12.811 2.1688 16.051 5.3388 16.051 9.2788V11.2788H14.051C12.951 11.2788 12.051 12.1788 12.051 13.2788V17.2788C12.051 18.3788 12.951 19.2788 14.051 19.2788H15.051C16.711 19.2788 18.051 17.9388 18.051 16.2788V9.2788C18.051 4.1088 13.691 -0.041201 8.45103 0.298799Z"
                            fill="#0078AE"
                          />
                        </svg>
                      </div>
                      <span className="d-inline-flex ms-4">
                        <h3>Access the podcast library</h3>
                      </span>
                    </Col>
                    <Col xs={12} className="pt-1">
                      <p className="mb-4" style={{ color: "#9F9FA1" }}>
                        Listen to current and past episiodes of Meridian’s
                        multiple audio and video series.
                      </p>
                      <Link
                        to="/public-programs/podcasts/integral-voices"
                        className="btn btn-primary"
                      >
                        Listen In{" "}
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="#FFFFFF"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg className="p-lg-5 p-4 ms-lg-3 white-bg">
                  <Row>
                    <Col xs={12} className="d-flex align-items-center mb-3">
                      <div className="meridian-blue-15-bg rounded icon d-inline-flex">
                        <svg
                          width="19"
                          height="21"
                          viewBox="0 0 19 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.051 0.278809C13.501 0.278809 13.051 0.728809 13.051 1.27881V2.27881H5.05103V1.27881C5.05103 0.728809 4.60103 0.278809 4.05103 0.278809C3.50103 0.278809 3.05103 0.728809 3.05103 1.27881V2.27881H2.05103C0.941025 2.27881 0.0610254 3.17881 0.0610254 4.27881L0.0510254 18.2788C0.0510254 19.3788 0.941025 20.2788 2.05103 20.2788H16.051C17.151 20.2788 18.051 19.3788 18.051 18.2788V4.27881C18.051 3.17881 17.151 2.27881 16.051 2.27881H15.051V1.27881C15.051 0.728809 14.601 0.278809 14.051 0.278809ZM16.051 18.2788H2.05103V8.27881H16.051V18.2788ZM8.05103 11.2788C8.05103 10.7288 8.50103 10.2788 9.05103 10.2788C9.60103 10.2788 10.051 10.7288 10.051 11.2788C10.051 11.8288 9.60103 12.2788 9.05103 12.2788C8.50103 12.2788 8.05103 11.8288 8.05103 11.2788ZM4.05103 11.2788C4.05103 10.7288 4.50103 10.2788 5.05103 10.2788C5.60103 10.2788 6.05103 10.7288 6.05103 11.2788C6.05103 11.8288 5.60103 12.2788 5.05103 12.2788C4.50103 12.2788 4.05103 11.8288 4.05103 11.2788ZM12.051 11.2788C12.051 10.7288 12.501 10.2788 13.051 10.2788C13.601 10.2788 14.051 10.7288 14.051 11.2788C14.051 11.8288 13.601 12.2788 13.051 12.2788C12.501 12.2788 12.051 11.8288 12.051 11.2788ZM8.05103 15.2788C8.05103 14.7288 8.50103 14.2788 9.05103 14.2788C9.60103 14.2788 10.051 14.7288 10.051 15.2788C10.051 15.8288 9.60103 16.2788 9.05103 16.2788C8.50103 16.2788 8.05103 15.8288 8.05103 15.2788ZM4.05103 15.2788C4.05103 14.7288 4.50103 14.2788 5.05103 14.2788C5.60103 14.2788 6.05103 14.7288 6.05103 15.2788C6.05103 15.8288 5.60103 16.2788 5.05103 16.2788C4.50103 16.2788 4.05103 15.8288 4.05103 15.2788ZM12.051 15.2788C12.051 14.7288 12.501 14.2788 13.051 14.2788C13.601 14.2788 14.051 14.7288 14.051 15.2788C14.051 15.8288 13.601 16.2788 13.051 16.2788C12.501 16.2788 12.051 15.8288 12.051 15.2788Z"
                            fill="#0078AE"
                          />
                        </svg>
                      </div>
                      <span className="d-inline-flex ms-4">
                        <h3>Meridian accepts applications year round</h3>
                      </span>
                    </Col>
                    <Col xs={12} className="pt-1">
                      <p className="mb-4" style={{ color: "#9F9FA1" }}>
                        Students can begin degree programs several times a year.
                      </p>
                      <Link
                        to="/admissions/graduate-admissions/apply-online"
                        className="btn btn-primary"
                      >
                        Take the Next Step{" "}
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="#FFFFFF"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <PageQuote
            quoteType="page-quote-reverse"
            quoteAuthor="— JOANNA MACY"
            quoteAuthorAttribute="Philosopher and Ecopsychologist"
            quoteHeadshot="https://web-cdn.meridianuniversity.edu/site-thumbs/joanna-macy-thumb.webp"
            quote="Meridian University is a gift to this moment in our history. The rare breadth of the intellectual journey it offers, and its exquisite attention to the ripening of the whole person, set a stunning example for graduate studies."
            quoteImg="https://web-cdn.meridianuniversity.edu/site-content-images/homepage-macy-quote-image.webp"
            quoteMobileImg="https://web-cdn.meridianuniversity.edu/site-content-images/homepage-macy-quote-image-mobile.webp"
          />
          <div id="announcements">
            <Container fluid="lg" className="py-4 py-lg-5">
              <Row className="py-3 py-lg-4 align-items-start">
                <Col xs={12}>
                  <h2 className="announcements_headline mb-xl-5 mb-4">
                    Latest Updates
                  </h2>
                </Col>
                {data.allMdx.nodes.map((node) => (
                  <Col
                    xl={4}
                    lg={12}
                    md={6}
                    xs={12}
                    key={node.frontmatter.title}
                    className="px-3 mb-4 mb-xl-5"
                  >
                    <Row className="align-items-center">
                      <Col
                        xl={12}
                        lg={6}
                        xs={12}
                        className="mb-4 announcement-container"
                      >
                        <Link to={node.frontmatter.link}>
                          <img
                            src={node.frontmatter.thumbnail}
                            alt={`${node.frontmatter.title} thumbnail`}
                            className="img-fluid"
                            loading="lazy"
                          />
                        </Link>
                      </Col>
                      <Col xl={12} lg={6} xs={12}>
                        <Link to={node.frontmatter.link}>
                          <h2 className="announcements_title">
                            {node.frontmatter.title}
                          </h2>
                        </Link>
                        <div className="announcements_descripton">
                          <Link to={node.frontmatter.link}>{node.excerpt}</Link>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
          <PageQuote
            quoteType="page-quote"
            quoteAuthor="— Thomas Berry"
            quote="The glory of the human has become the desolation of the Earth. The desolation of earth is now our greatest shame and biggest threat. Therefore, all programs, policies, activities and institutions must henceforth be judged primarily by the extent to which they inhibit, ignore or foster a mutually enhancing human/earth relationship."
            quoteImg="https://web-cdn.meridianuniversity.edu/site-content-images/homepage-berry-quote-image.webp"
          />
          <div id="homeBottom">
            <Container fluid="lg" className="mt-lg-5 my-4 px-2 px-lg-0">
              <Row className="justify-content-around my-lg-4 py-xl-5 px-4 py-0 gx-5 intro-boxes">
                <Col
                  xs={12}
                  md
                  className="p-lg-5 p-4 me-lg-3 me-md-2 d-flex mb-4 mb-md-0 apply-online-bg"
                >
                  <Row className="h-100 align-items-end">
                    <Col xs={12} className="pt-1">
                      <p className="mb-4 white-text">
                        Interested in our graduate degree programs? Applying to
                        Meridian is a straightforward online process.
                      </p>
                      <Link
                        to="/admissions/graduate-admissions/apply-online"
                        className="btn btn-secondary"
                      >
                        Apply Online{" "}
                        <svg
                          width="7"
                          height="11"
                          viewBox="0 0 7 11"
                          fill="#323337"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                            fill="#323337"
                          />
                        </svg>
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md
                  className="p-lg-5 p-4 ms-lg-3 ms-md-2 meridian-blue-bg contact-us"
                >
                  <Row>
                    <Col xs={12} className="d-flex align-items-center mb-3">
                      <h2>Contact Us</h2>
                    </Col>
                    <Col xs={12} className="pt-1">
                      <p className="mb-4">
                        Our Admissions Advisors are available Monday through
                        Friday, 9am to 5pm PT via voice and email.
                      </p>
                      <Row className="justify-content-between align-items-end">
                        <Col xs={12} xl={8}>
                          <p className="contact-link mb-2">
                            <a href="tel:+18332562295">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.0761 10.4798L11.9594 10.2382C11.4511 10.1798 10.9511 10.3548 10.5927 10.7132L9.05939 12.2465C6.70106 11.0465 4.76773 9.12148 3.56773 6.75482L5.10939 5.21315C5.46773 4.85482 5.64273 4.35482 5.58439 3.84648L5.34273 1.74648C5.24273 0.904818 4.53439 0.271484 3.68439 0.271484H2.24273C1.30106 0.271484 0.517726 1.05482 0.576059 1.99648C1.01773 9.11315 6.70939 14.7965 13.8177 15.2382C14.7594 15.2965 15.5427 14.5132 15.5427 13.5715V12.1298C15.5511 11.2882 14.9177 10.5798 14.0761 10.4798Z"
                                  fill="white"
                                />
                              </svg>
                              <span className="ms-2">+1 (833) 256-2295</span>
                            </a>
                          </p>
                          <p className="contact-link mb-2">
                            <a href="mailto:admissions@meridianuniversity.edu">
                              <svg
                                width="17"
                                height="14"
                                viewBox="0 0 17 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.8844 0.929688H2.21773C1.3469 0.929688 0.642316 1.64219 0.642316 2.51302L0.634399 12.013C0.634399 12.8839 1.3469 13.5964 2.21773 13.5964H14.8844C15.7552 13.5964 16.4677 12.8839 16.4677 12.013V2.51302C16.4677 1.64219 15.7552 0.929688 14.8844 0.929688ZM14.5677 4.29427L8.97065 7.79344C8.71732 7.95177 8.38482 7.95177 8.13148 7.79344L2.5344 4.29427C2.33648 4.1676 2.21773 3.95385 2.21773 3.72427C2.21773 3.19385 2.79565 2.87719 3.2469 3.15427L8.55107 6.47135L13.8552 3.15427C14.3065 2.87719 14.8844 3.19385 14.8844 3.72427C14.8844 3.95385 14.7656 4.1676 14.5677 4.29427Z"
                                  fill="white"
                                />
                              </svg>
                              <span className="ms-2">
                                admissions@meridianuniversity.edu
                              </span>
                            </a>
                          </p>
                          <p className="contact-link mb-0">
                            <Link to="/admissions/graduate-admissions/schedule-with-admissions">
                              <svg
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.2177 0.429688C11.7594 0.429688 11.3844 0.804687 11.3844 1.26302V2.09635H4.71769V1.26302C4.71769 0.804687 4.34269 0.429688 3.88436 0.429688C3.42603 0.429688 3.05103 0.804687 3.05103 1.26302V2.09635H2.21769C1.29269 2.09635 0.559359 2.84635 0.559359 3.76302L0.551025 15.4297C0.551025 16.3464 1.29269 17.0964 2.21769 17.0964H13.8844C14.801 17.0964 15.551 16.3464 15.551 15.4297V3.76302C15.551 2.84635 14.801 2.09635 13.8844 2.09635H13.051V1.26302C13.051 0.804687 12.676 0.429688 12.2177 0.429688ZM13.8844 15.4297H2.21769V7.09635H13.8844V15.4297ZM7.21769 9.59635C7.21769 9.13802 7.59269 8.76302 8.05103 8.76302C8.50936 8.76302 8.88436 9.13802 8.88436 9.59635C8.88436 10.0547 8.50936 10.4297 8.05103 10.4297C7.59269 10.4297 7.21769 10.0547 7.21769 9.59635ZM3.88436 9.59635C3.88436 9.13802 4.25936 8.76302 4.71769 8.76302C5.17603 8.76302 5.55103 9.13802 5.55103 9.59635C5.55103 10.0547 5.17603 10.4297 4.71769 10.4297C4.25936 10.4297 3.88436 10.0547 3.88436 9.59635ZM10.551 9.59635C10.551 9.13802 10.926 8.76302 11.3844 8.76302C11.8427 8.76302 12.2177 9.13802 12.2177 9.59635C12.2177 10.0547 11.8427 10.4297 11.3844 10.4297C10.926 10.4297 10.551 10.0547 10.551 9.59635ZM7.21769 12.9297C7.21769 12.4714 7.59269 12.0964 8.05103 12.0964C8.50936 12.0964 8.88436 12.4714 8.88436 12.9297C8.88436 13.388 8.50936 13.763 8.05103 13.763C7.59269 13.763 7.21769 13.388 7.21769 12.9297ZM3.88436 12.9297C3.88436 12.4714 4.25936 12.0964 4.71769 12.0964C5.17603 12.0964 5.55103 12.4714 5.55103 12.9297C5.55103 13.388 5.17603 13.763 4.71769 13.763C4.25936 13.763 3.88436 13.388 3.88436 12.9297ZM10.551 12.9297C10.551 12.4714 10.926 12.0964 11.3844 12.0964C11.8427 12.0964 12.2177 12.4714 12.2177 12.9297C12.2177 13.388 11.8427 13.763 11.3844 13.763C10.926 13.763 10.551 13.388 10.551 12.9297Z"
                                  fill="white"
                                />
                              </svg>
                              <span className="ms-2">
                                Schedule an Appointment with Admissions
                              </span>
                            </Link>
                          </p>
                        </Col>
                        <Col xs={12} xl={4}>
                          <Link
                            to="/about/contact-us"
                            className="btn btn-secondary"
                          >
                            Contact
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2159_14320)">
                                <path
                                  d="M3.2602 0.763184L8.26025 5.76318L3.2602 10.7632L1.84183 9.34471L5.4234 5.76318L1.84183 2.18166L3.2602 0.763184Z"
                                  fill="#323337"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2159_14320">
                                  <rect
                                    width="10"
                                    height="10"
                                    fill="white"
                                    transform="matrix(-1 0 0 1 10.051 0.763184)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Celebrating30Years />
            </Container>
          </div>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = ({ data }) => (
  <Seo
    title="Education That Transforms"
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
  />
);

export const query = graphql`
  query homePageQuery {
    mdx(fileAbsolutePath: { regex: "/home.mdx/" }) {
      frontmatter {
        subheadline
        headline
        contentQuoteLeftAuthor
        contentQuoteLeftImage
        contentQuoteLeftQuote
        contentQuoteRightAuthor
        contentQuoteRightImage
        contentQuoteRightQuote
        quote
        quoteAuthor
        metadata {
          Description
          Keywords
          Link
          Title
        }
      }
      body
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 9
      filter: {
        frontmatter: {
          tags: {
            in: [
              "announcements"
              "content"
              "student"
              "faculty"
              "alumni-stories"
            ]
          }
          hide: { ne: true }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          link
          thumbnail
        }
        excerpt(pruneLength: 245)
      }
    }
  }
`;

export default IndexPage;
