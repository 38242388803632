import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Celebrating30Years = () => {
  return (
    <>
      <Row className="py-4 py-lg-5">
        <Col className="px-lg-0">
          <img
            src="https://web-cdn.meridianuniversity.edu/site-content-images/celebrating-30-years.webp"
            alt="Meridion Celebrate 30 Years"
            className="img-fluid d-none d-md-block"
          />
          <img
            src="https://web-cdn.meridianuniversity.edu/site-content-images/celebrating-30-years-mobile.webp"
            alt="Meridion Celebrate 30 Years Mobile"
            className="img-fluid d-md-none"
          />
        </Col>
      </Row>
    </>
  );
};

export default Celebrating30Years;
