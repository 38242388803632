import * as React from "react";

const TimeConvert = ({ oisTime, homePage, form }) => {
  const date = new Date(oisTime * 1);
  const optionsOne = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  const optionsTwo = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  const optionsUTC = {
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  };
  const optionPrettyMonth = {
    month: "long",
    day: "numeric",
  };
  const FirstDateFormat = date.toLocaleString("en-US", optionsOne);
  const SecondDateFormat = date.toLocaleString("en-US", optionsTwo);
  const UTCDateFormat = date.toLocaleString("en-US", optionsUTC);
  const HomePagePrettyDate = date.toLocaleString("en-US", optionPrettyMonth);
  if (homePage === "true") {
    return <>{HomePagePrettyDate}</>;
  } else if (form === "true") {
    return <>{UTCDateFormat}</>;
  } else {
    return (
      <>
        <span>
          <span className="date">{FirstDateFormat}</span>
          <span className="time">{SecondDateFormat}</span>
        </span>
      </>
    );
  }
};

export default TimeConvert;
