import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TimeConvert from "./oisTimeConvert";
import "./styles/homeEventPanel.scss";

class HomeEventPanel extends React.Component {
  state = {
    loading: true,
    error: false,
    fetchedData: [],
  };

  componentDidMount() {
    const loader = document.querySelector("#loading");
    function displayLoading() {
      loader.classList.add("display");
      setTimeout(() => {
        loader.classList.remove("display");
      }, 5000);
    }
    function hideLoading() {
      loader.classList.remove("display");
    }

    fetch(
      "https://prod-03.southcentralus.logic.azure.com:443/workflows/0101f63da56744e3a59ca52308855ed7/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Gc8CMK2QVftM_CtqsaRMi5WvDxrFg-y6ro36HfHw6ho",
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        hideLoading();
        this.setState({
          fetchedData: json,
          loading: false,
        });
      });
    displayLoading();
  }

  render() {
    const { fetchedData } = this.state;
    const staticItemOne = {
      description:
        "Explore Meridian’s MBA in Creative Enterprise directly with program faculty.",
      event_pretty_name: "Attend an Information Meeting with MBA faculty",
      sign_up_page:
        "https://meridianuniversity.edu/admissions/graduate-admissions/info-sessions-and-events",
    };
    const staticItemTwo = {
      description:
        "Interested in Meridian’s Education Master’s and Doctoral Programs? Speak directly with program faculty.",
      event_pretty_name:
        "Attend an Information Meeting with EdD and MEd degree faculty",
      sign_up_page:
        "https://meridianuniversity.edu/admissions/graduate-admissions/info-sessions-and-events",
    };
    fetchedData.push(staticItemOne, staticItemTwo);
    return (
      <>
        <div id="homeEvents">
          <h2 className="mb-xl-5 mb-4 pb-1">Upcoming Events</h2>
          <Row lg={3} md={2} xs={1} className="gx-lg-4">
            <div id="loading" className="px-0">
              <Col lg={4} md={6} xs={12} className="event--loading">
                <div className="event--loading-content">
                  <div className="event--loading-text-container">
                    <div className="event-date--loading"></div>
                    <div className="event-title--loading"></div>
                    <div className="event-description--loading"></div>
                    <div className="event-description--loading"></div>
                    <div className="event-description--loading"></div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} xs={12} className="event--loading">
                <div className="event--loading-content">
                  <div className="event--loading-text-container">
                    <div className="event-date--loading"></div>
                    <div className="event-title--loading"></div>
                    <div className="event-description--loading"></div>
                    <div className="event-description--loading"></div>
                    <div className="event-description--loading"></div>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} xs={12} className="event--loading">
                <div className="event--loading-content">
                  <div className="event--loading-text-container">
                    <div className="event-date--loading"></div>
                    <div className="event-title--loading"></div>
                    <div className="event-description--loading"></div>
                    <div className="event-description--loading"></div>
                    <div className="event-description--loading"></div>
                  </div>
                </div>
              </Col>
            </div>
            {fetchedData
              .sort(
                ({ event_date: previousDate }, { event_date: currentDate }) =>
                  previousDate - currentDate,
              )
              .slice(0, 9)
              .map((OIS) => (
                <Col key={OIS.sfdc_campaign_id} className="fade event white-bg">
                  <a href={`${OIS.sign_up_page}`}>
                    <Row>
                      <Col className="mt-1 pt-2 px-4">
                        {OIS.event_date ? (
                          <h2 className="event-date">
                            <TimeConvert
                              oisTime={OIS.event_date}
                              homePage="true"
                            />
                          </h2>
                        ) : (
                          <h2 className="event-date spacer"></h2>
                        )}
                      </Col>
                      <Col xs={12} className="px-4">
                        <h3 className="event-title">{OIS.event_pretty_name}</h3>
                      </Col>
                      <Col xs={12} className="px-4">
                        <p className="event-description">{OIS.description}</p>
                      </Col>
                    </Row>
                  </a>
                </Col>
              ))}
          </Row>
        </div>
      </>
    );
  }
}

export default HomeEventPanel;
