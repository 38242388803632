import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/default-header.scss";

const img1 =
  "https://web-cdn.meridianuniversity.edu/header-images/home/homepage-hero-background-image-one.webp";
const img2 =
  "https://web-cdn.meridianuniversity.edu/header-images/home/homepage-hero-background-image-two.webp";
const img3 =
  "https://web-cdn.meridianuniversity.edu/header-images/home/homepage-hero-background-image-three.webp";
const img4 =
  "https://web-cdn.meridianuniversity.edu/header-images/home/homepage-hero-background-image-four.webp";

const pictureArray = [img1, img2, img3, img4];

class HomeHeader extends React.Component {
  state = {
    currentImageIndex: Math.floor(Math.random() * pictureArray.length),
  };

  componentDidMount() {
    this.changeImage();
  }

  changeImage = () => {
    const randomNumber = Math.floor(Math.random() * pictureArray.length);
    this.setState({
      currentImageIndex: randomNumber,
      bgStyle: {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url(${
          pictureArray[this.state.currentImageIndex]
        })`,
      },
    });
  };
  render() {
    return (
      <div id="muHomeHero" style={this.state.bgStyle}>
        <Container fluid="lg">
          <Row className="align-items-xl-center align-items-end h-100">
            <Col
              xl={5}
              lg={9}
              md={11}
              className="ms-xl-0 ms-md-4 ms-2 mb-xl-0 mb-lg-4 mb-md-3 mb-2 pb-xl-0 pb-lg-4 pb-md-3 pb-2"
            >
              <h1 className="mu-hero-headline mb-3 pb-1">
                Education That Transforms
              </h1>
              <h2 className="mu-hero-subheadline">
                Meridian Graduates are Reinventing the Professions of Business,
                Education, Leadership, and Psychology.
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomeHeader;
